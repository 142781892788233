import React, { useEffect }  from "react";
import { Paper, 
  Typography, 
  Grid, 
  Button, 
  TextField,
  Stepper,
  Step,
  StepLabel,
  Divider,
  CircularProgress,
  Backdrop,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem
 } from '@mui/material';

// import DropzoneInvoice from '../../modules/components/DropzoneInvoice'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from "moment";
import { getAuth } from "firebase/auth";
import { MuiFileInput } from 'mui-file-input'
// import DropzonePreRepairImage from '../../modules/components/DropzonePreRepairImage'
import PhoneInput from 'react-phone-input-2'
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection, query, where } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAVgHwu_fzmXe1vQzrPFUPBP4pnx2Zr8IQ",
  authDomain: "azteq-dc1d6.firebaseapp.com",
  databaseURL: "https://azteq-dc1d6-default-rtdb.firebaseio.com",
  projectId: "azteq-dc1d6",
  storageBucket: "azteq-dc1d6.firebasestorage.app",
  messagingSenderId: "747452880628",
  appId: "1:747452880628:web:9b5d8dbf3cfc0bf7f630a1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

const repaircost = [
  { phone_model: 'iphone 16', cost: 329 },
  { phone_model: 'iphone 16e', cost: 329 },
  { phone_model: 'iphone 16 plus', cost: 379 },
  { phone_model: 'iphone 16 pro', cost: 379 },
  { phone_model: 'iphone 16 pro max', cost: 428 },
];

function Index(props) {

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Repair Details', 'Summary', 'Completed'];
    
    const [product, setProduct] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [saveLoading, setSaveLoading] = React.useState(false);

    // step1
    const [repairDate, setRepairDate] = React.useState(null);
    const [repairAmount, setRepairAmount] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState("kh");
    const [selectedSC, setSelectedSC] = React.useState(null);
    const [isRepairDate, setIsRepairDate] = React.useState(false);
    const [isRepairAmount, setIsRepairAmount] = React.useState(false);
    const [isSelectedCountry, setIsSelectedCountry] = React.useState(false);
    const [isSelectedSC, setIsSelectedSC] = React.useState(false);
    const [isPostRepairImage, setIsPostRepairImage] = React.useState(false);
    const [isRepairInvoice, setIsRepairInvoice] = React.useState(false);
    const [isServiceRepairReport, setIsServiceRepairReport] = React.useState(false);

    const [PostRepairImage, setPostRepairImage] = React.useState(null);
    const [RepairInvoice, setRepairInvoice] = React.useState(null);
    const [ServiceRepairReport, setServiceRepairReport] = React.useState(null);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    
    useEffect(() => {
      
      if(props.selectedClaim)
      {
        const currentIndex = repaircost.map(val=> val.phone_model).indexOf(props.selectedClaim.phone_model)
        props.selectedClaim.repair_cost_limit = repaircost[currentIndex].cost;
        setProduct(props.selectedClaim)
        setLoading(false)
      }
      
  
    }, [props]);

    // step 1
    const handleRepairDate = (event) => {
      setRepairDate(event.target.value);
    }

    const handleCountryChange = (event) => {
      setSelectedCountry(event.target.value);
    }
  
    const handleSCChange = (event) => {
      setSelectedSC(event.target.value);
    }

    const handleSubmitStep1 = () => {

      let checkingCount = 0;

      if(repairDate){
        setIsRepairDate(false)
        checkingCount+=1;
      }
      else
      {
        setIsRepairDate(true)
      }
  
      if(repairAmount && repairAmount > 0){
        setIsRepairAmount(false)
        checkingCount+=1;
      }
      else
      {
        setIsRepairAmount(true)
      }
  
      if(selectedCountry){
        setIsSelectedCountry(false)
        checkingCount+=1;
      }
      else
      {
        setIsSelectedCountry(true)
      }
  
      if(selectedSC){
        setIsSelectedSC(false)
        checkingCount+=1;
      }
      else
      {
        setIsSelectedSC(true)
      }

      if(PostRepairImage && (PostRepairImage.type === "image/jpeg" 
        || PostRepairImage.type === "image/png" || PostRepairImage.type === "application/pdf" )){
        setIsPostRepairImage(false)
        checkingCount+=1;
      }
      else
      {
        setIsPostRepairImage(true)
      }
  
      if(RepairInvoice && (RepairInvoice.type === "image/jpeg" 
        || RepairInvoice.type === "image/png" || RepairInvoice.type === "application/pdf" )){
        setIsRepairInvoice(false)
        checkingCount+=1;
      }
      else
      {
        setIsRepairInvoice(true)
      }

      if(ServiceRepairReport && (ServiceRepairReport.type === "image/jpeg" 
        || ServiceRepairReport.type === "image/png" || ServiceRepairReport.type === "application/pdf" )){
        setIsServiceRepairReport(false)
        checkingCount+=1;
      }
      else
      {
        setIsServiceRepairReport(true)
      }

      if(checkingCount === 7){
        setActiveStep(1)
      }
    }

    const handleDropzonePostRepairImage = (files) => {
      setPostRepairImage(files)
    } 
  
    const handleDropzoneRepairInvoice = (files) => {
      setRepairInvoice(files)
    } 
  
    const handleDropzoneServiceRepairReport = (files) => {
      setServiceRepairReport(files)
    } 

    // step 2
    const handleSubmitStep2 = () => {
      setSaveLoading(true)

      const auth = getAuth();
      const user = auth.currentUser;

      const storage = getStorage(app);
      const storageRef = ref(storage, 
        `repair_detail_image/${Timestamp.fromDate(new Date()).toMillis()}_${PostRepairImage.name.replace(/\s/g,'')}`);
      

          // 'file' comes from the Blob or File API
          uploadBytes(storageRef, PostRepairImage).then((snapshot1) => {

            const storagerepairinvoice = getStorage(app);
            const storageRefrepairinvoice = ref(storagerepairinvoice, 
              `repair_detail_invoice/${Timestamp.fromDate(new Date()).toMillis()}_${RepairInvoice.name.replace(/\s/g,'')}`);
  
            uploadBytes(storageRefrepairinvoice, RepairInvoice).then((snapshot2) => {

              const storagerepairreport = getStorage(app);
              const storageRefrepairreport = ref(storagerepairreport, 
                `repair_detail_report/${Timestamp.fromDate(new Date()).toMillis()}_${ServiceRepairReport.name.replace(/\s/g,'')}`);
    
              uploadBytes(storageRefrepairreport, ServiceRepairReport).then((snapshot3) => {
                  
                  try
                  {
                    update(dbref(rtdb, `sc_claimed/${product.id}`), {

                      repair_detail_createdby: user.uid,
                      status: "submit_for_repair_detail",
                      repair_detail_created: Timestamp.fromDate(new Date()).seconds,
                      repair_detail_updated: Timestamp.fromDate(new Date()).seconds,
                      repair_date: repairDate,
                      repair_amount: repairAmount,
                      service_center: selectedSC,

                      repair_img_fullPath: snapshot1.metadata.fullPath || null,
                      repair_img_contentType: snapshot1.metadata.contentType || null,

                      repair_invoice_img_fullPath: snapshot2.metadata.fullPath || null,
                      repair_invoice_img_contentType: snapshot2.metadata.contentType || null,

                      repair_report_img_fullPath: snapshot3.metadata.fullPath || null,
                      repair_report_img_contentType: snapshot3.metadata.contentType || null,
                    }).then(()=>{

                      const emailID = `Claim-${Timestamp.fromDate(new Date()).toMillis()}`;
                      try
                      {
                        setDoc(doc(db, "mail", emailID), {
                          to: props.selectedClaim.customer_email !== null || props.selectedClaim.customer_email !== "" ? 
                        [props.selectedClaim.customer_email, user.email, "lionel.peh@instaprotection.com"]:
                        [user.email, "lionel.peh@instaprotection.com"],
                          message: {
                            subject: 'Falcon - Acknowledgement of Update Insurance',
                            html: `<!DOCTYPE html>
                            <html>
                            <head>
                            
                              <meta charset="utf-8">
                              <meta http-equiv="x-ua-compatible" content="ie=edge">
                              <title>Falcon - Acknowledgement of Update Insurance</title>
                              <meta name="viewport" content="width=device-width, initial-scale=1">
                              <style type="text/css">
                              /**
                               * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
                               */
                              @media screen {
                                @font-face {
                                  font-family: 'Source Sans Pro';
                                  font-style: normal;
                                  font-weight: 400;
                                  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
                                }
                                @font-face {
                                  font-family: 'Source Sans Pro';
                                  font-style: normal;
                                  font-weight: 700;
                                  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
                                }
                              }
                              /**
                               * Avoid browser level font resizing.
                               * 1. Windows Mobile
                               * 2. iOS / OSX
                               */
                              body,
                              table,
                              td,
                              a {
                                -ms-text-size-adjust: 100%; /* 1 */
                                -webkit-text-size-adjust: 100%; /* 2 */
                              }
                              /**
                               * Remove extra space added to tables and cells in Outlook.
                               */
                              table,
                              td {
                                mso-table-rspace: 0pt;
                                mso-table-lspace: 0pt;
                              }
                              /**
                               * Better fluid images in Internet Explorer.
                               */
                              img {
                                -ms-interpolation-mode: bicubic;
                              }
                              /**
                               * Remove blue links for iOS devices.
                               */
                              a[x-apple-data-detectors] {
                                font-family: inherit !important;
                                font-size: inherit !important;
                                font-weight: inherit !important;
                                line-height: inherit !important;
                                color: inherit !important;
                                text-decoration: none !important;
                              }
                              /**
                               * Fix centering issues in Android 4.4.
                               */
                              div[style*="margin: 16px 0;"] {
                                margin: 0 !important;
                              }
                              body {
                                width: 100% !important;
                                height: 100% !important;
                                padding: 0 !important;
                                margin: 0 !important;
                              }
                              /**
                               * Collapse table borders to avoid space between cells.
                               */
                              table {
                                border-collapse: collapse !important;
                              }
                              a {
                                color: #1a82e2;
                              }
                              img {
                                height: auto;
                                line-height: 100%;
                                text-decoration: none;
                                border: 0;
                                outline: none;
                              }
                              </style>
                            
                            </head>
                            <body style="background-color: #e9ecef;">
                            
                              <!-- start preheader -->
                              <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
                              Thank you for using the Falcon insurance. We are processing your application.
                              </div>
                              <!-- end preheader -->
                            
                              <!-- start body -->
                              <table border="0" cellpadding="0" cellspacing="0" width="100%">
                            
                                <!-- start logo -->
                                <tr>
                                  <td align="center" bgcolor="#e9ecef">
                                    <!--[if (gte mso 9)|(IE)]>
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                                    <tr>
                                    <td align="center" valign="top" width="600">
                                    <![endif]-->
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                                      <tr>
                                        <td align="center" valign="top" style="padding: 36px 24px;">
                                          <a href="https://screenwarranty.falconkh.com" target="_blank" style="display: inline-block;">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/azteq-dc1d6.firebasestorage.app/o/logo%2FFalcon-logo-small.png?alt=media&token=c9c979cd-185d-41d3-9a16-972a5dcfeed5" alt="Logo" border="0" width="150" style="display: block; width: 150px; max-width: 150px; min-width: 48px;">
                                          </a>
                                        </td>
                                      </tr>
                                    </table>
                                    <!--[if (gte mso 9)|(IE)]>
                                    </td>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                  </td>
                                </tr>
                                <!-- end logo -->
                            
                                <!-- start copy block -->
                                <tr>
                                  <td align="center" bgcolor="#e9ecef">
                                    <!--[if (gte mso 9)|(IE)]>
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                                    <tr>
                                    <td align="center" valign="top" width="600">
                                    <![endif]-->
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
        
                                    <!-- start copy -->
                                      <tr>
                                        <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                          <p style="margin: 0;">Dear ${props.selectedClaim.customer_name},</p>
                                        </td>
                                      </tr>
                                      <!-- end copy -->
                            
                                      <!-- start copy -->
                                      <tr>
                                        <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                          <p style="margin: 0;">Thank you for using the Falcon insurance. We are processing your application.</p>
                                        </td>
                                      </tr>
                                      <!-- end copy -->
                            
                                      <!-- start copy -->
                                      <tr>
                                        <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                          <p style="margin: 0;">Application reference no: ${product.id}</p>
                                          <p style="margin: 0;">Serial No: ${product.serialno}</p>
                                          <p style="margin: 0;">Product Model: ${product.phone_model}</p>
                                          <p style="margin: 0;">Product Color: ${product.phone_color}</p>
                                          <p style="margin: 0;">Product Capacity: ${product.phone_capacity}</p>

                                          <p style="margin: 0;">Repair Date: ${moment(repairDate).format("DD-MM-YYYY HH:mm")}</p>
                                          <p style="margin: 0;">Service Center: Castus Service Center</p>
                                          <p></p>
                                          <p style="margin: 0;">Please do not reply to this email.</p>
                                        </td>
                                      </tr>
                                      <!-- end copy -->
                            
                                      <!-- start copy -->
                                      <tr>
                                        <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                                          <p style="margin: 0;">Cheers,<br> Falcon </p>
                                        </td>
                                      </tr>
                                      <!-- end copy -->

                                      <!-- start copy -->
                                      <tr>
                                        <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                                          <p style="margin: 0;">Date: ${moment().format("DD-MM-YYYY HH:mm")}</p>
                                        </td>
                                      </tr>
                                      <!-- end copy -->
                            
                                    </table>
                                    <!--[if (gte mso 9)|(IE)]>
                                    </td>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                  </td>
                                </tr>
                                <!-- end copy block -->
                            
                              </table>
                              <!-- end body -->
                            
                            </body>
                            </html>`,
                          }
                        }).then(()=>{

                          
        
                        })

                        setSaveLoading(false)
                          setActiveStep(2)
                    }
                    catch(e)
                    {
                      // console.log(e)
                    }
                    
                  
                  })
                  }
                  catch(e)
                  {
                    // console.log(e)
                  }
              });
            })

          });
      
    }

    if(loading)
      {
        return (
          <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
            <CircularProgress 
              disableShrink
              color="primary" />
          </div>);
      }

    return(
      <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", padding: 20 }}>
        
        <Grid style={{ width: "100%" }}>
          <Stepper activeStep={activeStep} orientation={matches? "horizontal" : "vertical"} style={{ padding: 0}}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Divider style={{  opacity: .2, marginTop: 30 }}/>
        </Grid>

        <Grid container style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 20, maxWidth: 600 }}>

              <Grid container spacing={2} style={{ display: activeStep === 0 ?  "flex": "none" }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                        id="datetime-local"
                        label="Repair Date / Time *"
                        type="datetime-local"
                        fullWidth
                        value={repairDate}
                        error={isRepairDate}
                        onChange={handleRepairDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                                style={{ width: "100%"}}
                                autoComplete='off'
                                type="number"
                                error={isRepairAmount}
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        USD
                                      </InputAdornment>
                                    ),
                                    form: {
                                      autocomplete: 'off',
                                    },
                                }}
                                value={repairAmount}
                                onChange={(e)=> 
                                  {
                                    if(Number(e.target.value) <= (product.repair_cost_limit))
                                    {
                                      setRepairAmount(Number(e.target.value))
                                    }
                                    else{
                                      setRepairAmount((product.repair_cost_limit))
                                    }
                                  }}/>
                      <Typography style={{ fontSize: 10, marginTop: 5 }}>{`Repair Cost Limit : USD${(product.repair_cost_limit)}`}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                      <Select
                        labelId="option-simple-select-outlined-label"
                        id="option-simple-select-outlined"
                        value={selectedCountry}
                        error={isSelectedCountry}
                        label={"Country"}
                        onChange={handleCountryChange}
                      >
                        <MenuItem value={"kh"}>{"Cambodia"}</MenuItem>
                      </Select>
                    </FormControl>

                    
                  </Grid>

                  {selectedCountry ? <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Service Center</InputLabel>
                      <Select
                        labelId="option-simple-select-outlined-label"
                        id="option-simple-select-outlined"
                        value={selectedSC}
                        error={isSelectedSC}
                        label={"Service Center"}
                        onChange={handleSCChange}
                      >
                        <MenuItem value={"castus service center"}>{"Castus Service Center"}</MenuItem>
                      </Select>
                    </FormControl>

                    
                  </Grid> : null}

                  <Grid item xs={12} md={12}>
                    <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Post Repair Image 
                    </Typography>
                    <MuiFileInput color='primary'
                      required
                      style={{ width: "100%"}}
                      error={isPostRepairImage}
                      placeholder="Upload Post Repair Image*"
                      getInputText={(value) => value ? value.name : 'Upload Post Repair Image*'}
                      inputProps={{ accept: 'image/jpeg,image/png,application/pdf' }}
                      value={PostRepairImage} onChange={handleDropzonePostRepairImage} />
                  </Grid>

                  <Grid  item xs={12} md={12}>
                    <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Repair Invoice
                    </Typography>
                    <MuiFileInput color='primary'
                      required
                      style={{ width: "100%"}}
                      error={isRepairInvoice}
                      placeholder="Upload Post Repair Invoice*"
                      getInputText={(value) => value ? value.name : 'Upload Post Repair Invoice*'}
                      inputProps={{ accept: 'image/jpeg,image/png,application/pdf' }}
                      value={RepairInvoice} onChange={handleDropzoneRepairInvoice} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Service Repair Report
                    </Typography>
                    <MuiFileInput color='primary'
                      required
                      style={{ width: "100%"}}
                      error={isServiceRepairReport}
                      placeholder="Upload Service Repair Report*"
                      getInputText={(value) => value ? value.name : 'Upload Service Repair Report*'}
                      inputProps={{ accept: 'image/jpeg,image/png,application/pdf' }}
                      value={ServiceRepairReport} onChange={handleDropzoneServiceRepairReport} />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Button
                      style={{textTransform: 'none', marginLeft: 10   }}
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={()=> handleSubmitStep1()}
                    >
                  Next
                    </Button>
                  </Grid>

            </Grid>

            <Grid container spacing={2} style={{ display: activeStep === 1 ?  "flex": "none" }}>

              <Grid item xs={12}  style={{ marginBottom: 10 }}>
                <Typography color="primary" variant='body1' style={{ fontWeight: "bold" }}>
                  Product Details
                </Typography>

                <Grid container  style={{ marginTop: 20 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Serial No.
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Brand
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Model
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Color
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Capacity
                    </Typography>

                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                    Activation Start Date
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                    Coverage End Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {product.serialno ? product.serialno : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {"Apple"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.phone_model ? product.phone_model : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.phone_color ? product.phone_color : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.phone_capacity ? product.phone_capacity : "-"}
                    </Typography>
                    <Typography variant='body2'>
                    {product.purchase_date ? moment(product.purchase_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                    {product.purchase_date ? moment(product.purchase_date).add(12, 'M').format("DD-MM-YYYY") : "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography color="primary" variant='body1' style={{ fontWeight: "bold", marginTop: 20 }}>
                  Repair Details
                </Typography>

                <Grid container  style={{ marginTop: 10 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Repair Date/Time 
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Repair Amount
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Service Center
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                    Post Repair Image
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                    Repair Invoice
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                    Service Repair Report
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {repairDate ? moment(repairDate).format("DD-MM-YYYY HH:mm") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {repairAmount ? `USD ${repairAmount}` : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {"Castus Service Center"}
                    </Typography>
                    <Typography variant='body2'>
                      {`${PostRepairImage ? PostRepairImage.name : ""}`}
                    </Typography>
                    <Typography variant='body2'>
                      {`${RepairInvoice ? RepairInvoice.name : ""}`}
                    </Typography>
                    <Typography variant='body2'>
                      {`${ServiceRepairReport ? ServiceRepairReport.name : ""}`}
                    </Typography>
                  </Grid>
                </Grid>
                

              </Grid>

              <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    variant="contained"
                    disableElevation
                    onClick={()=> setActiveStep(0)}
                  >
                Back
                  </Button>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> handleSubmitStep2()}
                  >
                Submit
                  </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ display: activeStep === 2 ?  "flex": "none" }}>

              <Grid item xs={12}  style={{ marginBottom: 10, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Typography align="center" color="primary" variant='body1' style={{ fontWeight: "bold", fontSize: 24, display: "flex", justifyContent: "center" }}>
                  Thank you for your submission!
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> props.handleClose()}
                  >
                Back
                  </Button>
                </Grid>
            </Grid>

        </Grid>

        <Backdrop style={{ zIndex: 999 }} open={saveLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    )
}

export default Index;